import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMenuState } from "../../actions/app";

const HamburgerButton = () => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.app.menu);
  // const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);

  return (
    <button
      className={"navbar__menu " + (menu ? "active" : "")}
      aria-label="Open main menu"
      onClick={() => dispatch(changeMenuState(!menu))}
    >
      <span className="navbar__burger"></span>
      <span className="navbar__burger"></span>
      <span className="navbar__burger_last"></span>
    </button>
  );
};

export default HamburgerButton;
