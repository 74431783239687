import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

import useOnClickOutside from "../../hooks/onClickOutside";

import { Fade } from "react-awesome-reveal";

import HamburgerButton from "./HamburgerButton";
import BackButton from "./BackButton";
import SideMenu from "./SideMenu";

import LanguageSwither from "./LanguageSwither";
import { useDispatch, useSelector } from "react-redux";
import { changeMenuState } from "../../actions/app";

const MainMenu = () => {
  const node = useRef();
  const dispatch = useDispatch();
  const isMenuBackOnly = useSelector((state) => state.app.isMenuBackOnly);
  useOnClickOutside(node, () => {
    dispatch(changeMenuState(false));
  });

  return (
    <nav ref={node} className="navbar">
      <div className="mva z-20">
        <Fade>
          <NavLink to="/">
            <img
              className="main-logo"
              src="./img/logo.svg"
              alt="Vladislav"
              width="100%"
              height="100%"
            ></img>
          </NavLink>
        </Fade>
      </div>
      <div className="mva z-20">
        <Fade>
          <LanguageSwither />
        </Fade>
      </div>
      <div className="mva z-20">
        <Fade direction="up">
          {isMenuBackOnly ? <BackButton /> : <HamburgerButton />}
        </Fade>
      </div>
      <SideMenu className="mva z-10" />
    </nav>
  );
};

export default MainMenu;
