import React, { useEffect } from "react";

import Header from "./Header";
import About from "./About";
import Work from "./Work";
import Contact from "./Contact";
import ContactForm from "./ContactForm";
import Skills from "./Skills";

import Fade from "react-reveal/Fade";

import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { useDispatch } from "react-redux";
import { setMenuIsBackOnly } from "../../store/appReducer";

const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // scroll.scrollToTop();
    document.title = "V.I. Portfolio";
    dispatch(setMenuIsBackOnly(false));
  }, [dispatch]);

  return (
    <div className="App">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <h1 className="heading-background">VLADISLAV</h1>
      <Fade left>
        <Header />
      </Fade>
      <Fade right>
        <About />
      </Fade>
      <Fade left>
        <Skills />
      </Fade>
      <Fade right>
        <Work />
      </Fade>
      <Fade bottom>
        <Contact />
      </Fade>
      <ContactForm />
      <NotificationContainer />
    </div>
  );
};

export default HomePage;
