import React from "react";
import { Link } from "react-scroll";

import { ReactComponent as BackIcon } from "../../assets/icons/back-white.svg";

import { useNavigate } from "react-router-dom";
const HamburgerButton = () => {
  const navigate = useNavigate();
  return (
    <Link
      className="back-button"
      to="/"
      activeClass="active"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      onClick={() => navigate(-1)}
    >
      <BackIcon className="back-icon" />
    </Link>
  );
};

export default HamburgerButton;
