import React, { useState } from "react";
// import { Fade } from "react-awesome-reveal";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

import { NotificationManager } from "react-notifications";
const tokenApi = process.env.REACT_APP_TELEGRAM_TOKEN;

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const showNotificationSuccess = (message) => {
    NotificationManager.success(message, "", 5000);
  };

  const showNotificationError = (message) => {
    NotificationManager.error(message, "", 5000);
  };

  const isValidEmail = (email) => {
    const emailRegex =
      //eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    return emailRegex.test(email.toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var errors = 0;
    if (!isValidEmail(email)) {
      errors++;
      showNotificationError(
        i18n.t("contactForm.inputError", { label: i18n.t("contactForm.eMail") })
      );
    }
    if (name === "") {
      errors++;
      showNotificationError(
        i18n.t("contactForm.inputError", { label: i18n.t("contactForm.name") })
      );
    }

    if (message === "") {
      errors++;
      showNotificationError(
        i18n.t("contactForm.inputError", {
          label: i18n.t("contactForm.message"),
        })
      );
    }

    if (errors === 0) {
      const tgMsg =
        `\u{1F514} <b>Оповещение формы обратной связи:</b>\n` +
        `\u{1F310} <b>Сайт:</b> [vskill.ru]\n` +
        `\u{1F30F} <b>Язык:</b> ${i18n.language.toUpperCase()}\n` +
        `\u{2139} <b>Имя:</b> ${name}\n` +
        `\u{1F4E7} <b>E-Mail:</b> ${email}\n` +
        `\u{1F4AC} <b>Сообщение:</b>\n${message}`;
      fetch(
        `https://api.telegram.org/bot${tokenApi}/sendMessage?chat_id=368361802&text=${encodeURIComponent(
          tgMsg
        )}&parse_mode=html`,
        {
          method: "GET",
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.ok === true)
            showNotificationSuccess(i18n.t("contactForm.successSend"));
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="ContactForm section">
      {/* <Fade direction="up"> */}
        <form
          className="ContactForm_form"
          method="POST"
          onSubmit={handleSubmit}
          netlify="true"
        >
          <input
            type="text"
            placeholder={i18n.t("contactForm.name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder={i18n.t("contactForm.eMail")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            type="text"
            placeholder={i18n.t("contactForm.message")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input type="submit" value={i18n.t("contactForm.button")} />
        </form>
      {/* </Fade> */}
    </div>
  );
};

export default withTranslation()(ContactForm);
