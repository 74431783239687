import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

// import { Fade, Slide } from "react-awesome-reveal";

import Fade from "react-reveal/Fade";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import { setMenuIsBackOnly } from "../../store/appReducer";

const ProjectPage = ({ id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    scroll.scrollToTop();
    dispatch(setMenuIsBackOnly(true));
    document.title = i18n.t(`projects.${id}.title`);
  });

  const renderSlide = (slide, index) => {
    if (slide.video === undefined) {
      return (
        <div key={index}>
          <img src={slide.img} alt={slide.desc} />
          <p className="legend">{slide.desc}</p>
        </div>
      );
    } else {
      return (
        <video key={index} src={slide.video} autoPlay loop>
          <img src={slide.img} alt={slide.desc} />
          Sorry, your browser doesn't support embedded videos, but don't worry,
          you can <a href={slide.video}>download it</a>
          and watch it with your favorite video player!
        </video>
      );
    }
  };

  return (
    <div name={i18n.t(`projects.${id}.title`)}>
      {/* <YMInitializer accounts={[86905602]} options={{webvisor: true}} /> */}
      <h1 className="heading-background">{i18n.t(`projects.${id}.title`)}</h1>
      {/* <MainMenu /> */}
      <div className="My-Project">
        <Fade top>
          <h1 className="ssection-header heading project-header">
            <span>{i18n.t(`projects.${id}.title`)}</span>
            <span className="year">{i18n.t(`projects.${id}.date`)}</span>
          </h1>
        </Fade>
        <Fade left>
          <h2 className="main-points">
            {i18n.t(`projects.${id}.main_points`)}
          </h2>
        </Fade>

        {i18n.exists(`projects.${id}.link`) && (
          <a
            href={i18n.t(`projects.${id}.link`)}
            target="_blank"
            className="description link"
            rel="noreferrer"
          >
            DEMO
          </a>
        )}
        {i18n.exists(`projects.${id}.git_link`) && (
          <a
            href={i18n.t(`projects.${id}.git_link`)}
            target="_blank"
            className="description link"
            style={{ paddingLeft: "10px" }}
            rel="noreferrer"
          >
            SOURCE
          </a>
        )}

        <Fade bottom>
          <h3 className="description">
            {i18n.t(`projects.${id}.description`)}
          </h3>
        </Fade>

        <Carousel
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          className="carousel-style"
          statusFormatter={(current, total) =>
            `${i18n.t("slide")} ${current} ${i18n.t("slideOf")} ${total}`
          }
        >
          {i18n
            .t(`projects.${id}.slides`, { returnObjects: true })
            .map((slide, index) => renderSlide(slide, index))}
        </Carousel>
      </div>
    </div>
  );
};

export default withTranslation()(ProjectPage);
