import React from "react";
// import { Fade } from "react-awesome-reveal";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

const Contact = () => {
  return (
    <div className="Contact section">
      <h1 className="section-header heading">
        {/* <Fade direction="up" cascade={true}> */}
          {i18n.t("contactHeader")}
        {/* </Fade> */}
      </h1>
      {/* <Fade direction="up"> */}
        <div className="contact-content">
          <h1
            className="big-text"
            dangerouslySetInnerHTML={{ __html: i18n.t(`contactBigText`) }}
          ></h1>
          <ul
            className={`social-contacts social-contacts-${
              i18n.t("socials", { returnObjects: true }).length
            }`}
          >
            {i18n.t("socials", { returnObjects: true }).map((social, index) => {
              return (
                <li key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={social.url}
                    onClick={() => {
                      window.ym(86905602, "reachGoal", "MailButtonClick");
                    }}
                  >
                    <img
                      className="social-icon"
                      src={social.icon}
                      alt={social.name}
                      width="100%"
                      height="100%"
                    ></img>
                    <p>{social.text}</p>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      {/* </Fade> */}
    </div>
  );
};

export default withTranslation()(Contact);
