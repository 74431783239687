import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const changeLang = () => {
    i18n.language.includes("ru")
      ? i18n.changeLanguage("en")
      : i18n.changeLanguage("ru");
  };
  return (
    <div className="lang-wrapper">
      <img
        className="langIcon"
        alt={i18n.language}
        src={`./icons/lang/${i18n.language.substring(0, 2)}.svg`}
        onClick={() => changeLang()}
      ></img>
    </div>
  );
};
export default LanguageSwitcher;
