import React from "react";
// import { Fade } from "react-awesome-reveal";
import { Link } from "react-scroll";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

const Header = () => {
  return (
    <div className="App-header section">
      {/* <Fade direction="up"> */}
        <div className="header-title">
          {i18n
            .t("headerTagline", { returnObjects: true })
            .map((value, index) => {
              return (
                <p className="header-line" key={index}>
                  {value}
                </p>
              );
            })}
          <button>
            <span>
              <Link
                activeClass="active"
                to="Contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                {i18n.t("contactButton")}
              </Link>
            </span>
          </button>
        </div>
      {/* </Fade> */}
    </div>
  );
};

export default withTranslation()(Header);
