import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { animateScroll as scroll } from "react-scroll";
import MainMenu from "../Navbar/MainMenu";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import { setMenuIsBackOnly } from "../../store/appReducer";

const ProjectPage = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    scroll.scrollToTop();
    dispatch(setMenuIsBackOnly(true));
    document.title = "Error 404";
  });

  return (
    <div name={props.title}>
      <h1 className="heading-background">404ERROR</h1>
      <MainMenu />
      <div className="error-page-container">
        <Fade direction="up">
          <div className="error-page">
            <img
              className="error-page-img"
              src="./icons/error-404.svg"
              alt="Ошибка 404"
            />
          </div>
        </Fade>
        <Fade direction="up" cascade={true}>
          <h1 className="error-page-header">{i18n.t("404error")}</h1>
        </Fade>
      </div>
    </div>
  );
};

export default withTranslation()(ProjectPage);
