import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./assets/locales/en.json";
import translationRU from "./assets/locales/ru.json";

// const lng = ["ru"];
const fallbackLng = ["ru"];
const availableLanguages = ["en", "ru"];

const resources = {
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    // lng,
    fallbackLng, // fallback language is russian.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    react: {
      useSuspense: false,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
