import React from "react";
// import { Fade } from "react-awesome-reveal";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

const About = () => {
  return (
    <div className="About section">
      <h1 className="section-header heading">
        {/* <Fade direction="up" cascade={true}> */}
          {i18n.t("aboutHeader")}
        {/* </Fade> */}
      </h1>
      <div className="about-content">
        {/* <Fade direction="up"> */}
          <p>
            <span dangerouslySetInnerHTML={{ __html: i18n.t("aboutText") }} />
          </p>
        {/* </Fade> */}
        <div className="image-container">
          {/* <Fade direction="up"> */}
            {i18n.t("showAboutImage") ? (
              <img
                className="about-image"
                src={i18n.t("aboutImage")}
                alt="About"
                width="100%"
                height="100%"
              />
            ) : null}
          {/* </Fade> */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(About);
