import React from "react";
import { Link } from "react-scroll";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeMenuState } from "../../actions/app";
import { toggleMenu } from "../../store/appReducer";

const SideMenu = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.app.menu);

  const MenuLinkTo = (to, name, className, onClick) => {
    return (
      <Link
        activeClass="active"
        to={to}
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
        className={className}
        onClick={onClick}
      >
        {name}
      </Link>
    );
  };

  return (
    <div
      className={"side__menu " + (menu ? "opened" : "")}
      onClick={() => dispatch(toggleMenu())}
    >
      {MenuLinkTo("App-header", i18n.t("menu.home"), "nav__link", () =>
        dispatch(changeMenuState(false))
      )}
      {MenuLinkTo("About", i18n.t("menu.about"), "nav__link", () =>
        dispatch(changeMenuState(false))
      )}

      {MenuLinkTo("Work", i18n.t("menu.works"), "nav__link", () =>
        dispatch(changeMenuState(false))
      )}
      {MenuLinkTo("Contact", i18n.t("menu.contacts"), "nav__link", () =>
        dispatch(changeMenuState(false))
      )}
    </div>
  );
};

export default SideMenu;
