import React from "react";
import Project from "./Project";
// import { Fade } from "react-awesome-reveal";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

const Work = () => {
  return (
    <div className="Work section">
      <h1 className="section-header heading">
        {/* <Fade direction="up" cascade={true}> */}
          {i18n.t("projectHeader")}
        {/* </Fade> */}
      </h1>
      <div className="work-content">
        {i18n.t("projects", { returnObjects: true }).map((project, index) => {
          return (
            <Project
              key={index}
              title={project.title}
              service={project.service}
              imageSrc={project.imageSrc}
              url={project.url}
            ></Project>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(Work);
