import React from "react";
// import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const Project = (props) => {
  return (
    // <Fade direction="up">
      <div className="project">
        <div className="img-container">
          <Link to={props.url}>
            <img
              src={props.imageSrc}
              alt={props.title}
              className="project-img"
            ></img>
          </Link>
        </div>
        <h1 className="project-title">{props.title}</h1>
        <p className="project-desc">{props.service}</p>
      </div>
    // </Fade>
  );
};

export default Project;
