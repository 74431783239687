import React from "react";
// import { Fade } from "react-awesome-reveal";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

const Skills = () => {
  return (
    // <Fade direction="up">
      <div className="Skills section">
        <h1 className="section-header heading">
          {/* <Fade direction="up" cascade={true}> */}
            {i18n.t("skillsHeader")}
          {/* </Fade> */}
        </h1>
        <div className="skills-graphs">
          {i18n
            .t("skillsContent", { returnObjects: true })
            .map((skill, index) => {
              return (
                <CircularProgressbarWithChildren
                  className={"circular-chart"}
                  value={skill.percentage}
                  key={index}
                >
                  <img
                    className="skill-icon"
                    src={skill.imageSrc}
                    alt={skill.imageAlt}
                    width="100%"
                    height="100%"
                  />
                  <div className="dropdown-content">
                    {skill.content.map((ctn, idx) => (
                      <p key={idx}>{ctn}</p>
                    ))}
                  </div>
                </CircularProgressbarWithChildren>
              );
            })}
        </div>
      </div>
    // </Fade>
  );
};

export default withTranslation()(Skills);
