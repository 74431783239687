import { setMenu } from "../store/appReducer";
export function changeMenuState(state) {
  return async (dispatch) => {
    if (state) {
      document.body.classList.add("menu-opened");
    } else {
      document.body.classList.remove("menu-opened");
    }
    dispatch(setMenu(state));
  };
}
