import React, { useEffect } from "react";
// import { Routes, Route } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./sass/styles.scss";

import HomePage from "./components/HomePage/HomePage";
import ProjectPage from "./components/ProjectPage/ProjectPage";
import Error404Page from "./components/Error404Page/Error404Page";
import MainMenu from "./components/Navbar/MainMenu";

const App = (props) => {
  useEffect(() => {
    // if (props.location.pathname !== prevProps.location.pathname) {
    //   window.ym(86905602, "hit", window.location.href);
    // }
  });

  return (
    <BrowserRouter>
      <MainMenu />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/harvestech" element={<ProjectPage id="0" />} />
        <Route exact path="/infosecurity" element={<ProjectPage id="1" />} />
        <Route exact path="/onix" element={<ProjectPage id="2" />} />
        <Route exact path="/veterany" element={<ProjectPage id="3" />} />
        <Route exact path="/primehookah" element={<ProjectPage id="4" />} />
        <Route exact path="/cloudstore" element={<ProjectPage id="5" />} />
        <Route exact path="/newshub" element={<ProjectPage id="6" />} />
        <Route exact path="/devoverflow" element={<ProjectPage id="7" />} />
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
